import React from 'react';
import { DefaultLayout,
  OverlapCardLayout,
  PressReleaseLayout,
  SearchLayout
} from 'react-sitecore-library';

/*
  APP LAYOUT
  This is where the app"s HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = (props) => {
  let layouts = {
    'a0ea8837-439f-4323-a301-1119c1ed0443': 'Default',
    'bd35f9b3-ff08-4b44-af54-b5def5fef960': 'OverlapCard',
    '2be3a6a2-374b-4749-9731-26b8bb7af959': 'PressRelease',
    '8d45cfbd-a1de-4e9a-82f0-c85247cffc32': 'Search'
  };
  const appEnv =   props.appEnv === null || typeof props.appEnv === 'undefined' ? 'dev' :  props.appEnv;
  let appDomain = '';
  if (appEnv === 'prod')
    appDomain = 'https://www.massmutual.com';
  else if (appEnv === 'preview')
    appDomain = 'https://www2.massmutual.com';
  else if (appEnv === 'qa')
    appDomain = 'https://www-qa.massmutual.com';
  else
    appDomain = 'https://www-dev.massmutual.com'; 

  const componentProps = {
    appName: 'mmcom',
    appDomain: appDomain,
    env: appEnv,
    pagePath: props.currentPagePath,
    pageQuery: props.pageQuery
  };
  const headTagsProps = {
    appName: 'mmcom',
    fields: props.route.fields,
    itemId: props.route.itemId,
    pagePath: props.currentPagePath,
    pageQuery: props.pageQuery,
    env: appEnv,
    authStatus: props.authStatus,
    tealiumType: 'main' // No tealium tagging for test app
  };
  switch (layouts[props.route.layoutId]) {
    case 'Default':
      return <DefaultLayout {...props} componentProps = {componentProps} headTagsProps={headTagsProps} />;
    case 'OverlapCard':
      return <OverlapCardLayout {...props} componentProps = {componentProps} headTagsProps={headTagsProps} />;
    case 'PressRelease':
      return <PressReleaseLayout {...props} componentProps = {componentProps} headTagsProps={headTagsProps} />;
    case 'Search':
      return <SearchLayout {...props} componentProps = {componentProps} headTagsProps={headTagsProps} />;
    default:
      return null;
  }
};

export default Layout;

